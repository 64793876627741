import 'scss/article.scss';
import 'scss/articles-list.scss';
import 'scss/button.scss';
import 'scss/footer.scss';
import 'scss/form.scss';
import 'scss/global.scss';
import 'scss/instagram-feed.scss';
import 'scss/hero.scss';
import 'scss/nav.scss';
import 'scss/utilities.scss';
